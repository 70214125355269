import React, { Component } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import withLocation from "../components/withLocation"
import { Link, navigate } from "gatsby"
import { ProcessOrderInParse, UploadDeviceForTrade } from "../services/parse-bridge"
import Loader from "react-loader-spinner"
import SEO from "../components/seo"
import { colours } from "../style/colours"
import WipayConfirmation from "../components/order-confirmation.js/wipay-confirmation"

const Center = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 80vh;
    text-align: center;
    padding: 20px;
`

class OrderFailedPage extends Component {
    state = {
        isLoading: true,
        failed: false,
    }

    render() {
        return (
            <Layout>
                <Center>
                    <h2>Something went wrong with your transaction</h2>
                    <CartButton black onClick={() => navigate("/checkout")}>
                        Retry Order
                    </CartButton>
                    <Link
                        style={{
                            fontFamily: "Muli",
                            color: "red",
                            textDecoration: "none",
                            marginTop: 10,
                        }}
                        to="/"
                    >
                        Cancel Order
                    </Link>
                </Center>
            </Layout>
        )
    }
}

export default withLocation(OrderFailedPage)

const CartButton = styled.button`
  color: white;
  background: ${colours.greenDark};
  border: 1px solid ${colours.greenLight};
  outline: none;
  text-transform: uppercase;
  padding: 8px 15px;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
  width: 80%;


  :disabled {
    background: #a7a7a7;
    cursor: not-allowed;
    border: 1px solid #a7a7a7;

    :hover {
      background:  #a7a7a7;
  }
  }

  :hover {
    background: ${colours.greenDark};
    color: white;
  }
`